$background:#f1f5fd;
$color:#080e1b;

/*Color variables*/
$primary1:#e67605;
$primary2:#e84312;
$primary3:#ff6b12;
$primary4:#e81937;
$primary6:#05a01f;
$primary7:#f3532f;
$primary9:#0ab2e6;
$primary11:#f73754;
$primary12:#e81412;
$primary13:#e72a1a;

$secondary1:#3c176f;
$secondary2:#0a331e;
$secondary3:#1a337b;
$secondary4:#030d2f;
$secondary5:#480233;
$secondary6:#190300;
$secondary7:#09526f;
$secondary8:#350011;
$secondary9:#08024c;
$secondary13:#7f09a5;


$success:#039e26;
$warning:#ff8819;
$danger:#ff382b;
$blue:#467fcf;
$yellow:#ffa22b;
$pink:#ff2b88;
$teal:#05e6e6;
$purple:#604dd8 ;
$dark:#343a40;
$indigo:#6574cd ;
$white:#fff;
$black:#000;

/*white variables*/
$white-1:rgba(255, 255, 255, 0.1);
$white-2:rgba(255, 255, 255, 0.2);
$white-3:rgba(255, 255, 255, 0.3);
$white-4 :rgba(255, 255, 255, 0.4);
$white-5 :rgba(255, 255, 255, 0.5);
$white-6 :rgba(255, 255, 255, 0.6);
$white-7 :rgba(255, 255, 255, 0.7);
$white-8 :rgba(255, 255, 255, 0.8);
$white-9 :rgba(255, 255, 255, 0.9);
$white-05:rgba(255, 255, 255, 0.05);
$white-08:rgba(255, 255, 255, 0.08);
$white-75:rgba(255, 255, 255, 0.075);

/*black variables*/
$black-1:rgba(0, 0, 0, 0.1);
$black-2:rgba(0, 0, 0, 0.2);
$black-3:rgba(0, 0, 0, 0.3);
$black-4:rgba(0, 0, 0, 0.4);
$black-5:rgba(0, 0, 0, 0.5);
$black-6:rgba(0, 0, 0, 0.6);
$black-7:rgba(0, 0, 0, 0.7);
$black-8:rgba(0, 0, 0, 0.8);
$black-9:rgba(0, 0, 0, 0.9);
$black-05:rgba(0, 0, 0, 0.05);


/***********dark-theme**************/

$dark-theme: #212332;
$dark-theme2: #2a2e3f;
$dark-border: #32394e;
$dark-color:#ccd4e0;
$dark-shadow:0 0 40px 0 rgb(36, 38, 48);
